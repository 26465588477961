<template>
  <div class="wameed-dashboard-page-content mb-5">
    <page-header
      :title="$t('settings.operation_log')"
      :sub-title="$t('settings.operation_log_sub_title')"
    />

    <div>
      <filter-header
        :content="content"
        @applyFilter="applyFilter"
        @resetFilter="resetFilter"
        @orderApplicants="orderApplicants"
      />
    </div>
    <section
      class="
        wameed-dashboard-page-content_body
        d-flex
        flex-column
        justify-content-between
      "
    >
      <div class="d-flex flex-wrap flex-1 px-2">
        <b-col lg="12" md="12" class="px-0">
          <div class="w-table d-flex flex-column">
            <w-tables
              v-if="getAllOperations"
              :per-page="filterData.per_page"
              :items="getAllOperations"
              :fields="fields"
              :status="status"
              :custimized-items="[{ name: 'name' }, { name: 'created_at' }]"
            >
              <template slot="name" slot-scope="{ data }">
                <div class="d-flex justify-center align-items-center">
                  <b-avatar variant="" size="50">
                    <img
                      v-if="data.item.photo"
                      :src="
                        data.item.photo.full_path + data.item.photo.file_name
                      "
                    />
                    <default-user-image-icon v-else />
                  </b-avatar>
                  <span class="text-font-main mx-3">{{ data.item.name }}</span>
                </div>
              </template>
              <template slot="created_at" slot-scope="{ data }">
                <div class="d-flex justify-center align-items-center" dir="ltr">
                  <span class="text-font-main">{{
                    formatDate(data.item.created_at)
                  }}</span>
                </div>
              </template>
            </w-tables>
            <wameed-no-data
              v-if="getAllOperations && getAllOperations.length < 1"
              icon="nodata-icon"
              :title="$t('offers_page.no_data')"
              :sub-title="$t('offers_page.no_data_text')"
            />
          </div>
        </b-col>
        <b-col
          v-if="getAllOperations && getAllOperations.length > 0"
          lg="12"
          md="12"
          class="px-0"
        >
          <wameed-pagination
            v-model="filterData.page"
            :page="filterData.page"
            :total-items="getTotalOperations.totalItems"
            :per_page="filterData.per_page"
            @changePage="changePage"
            @changeCurrentPage="changeCurrentPage"
          />
        </b-col>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WameedPagination from '@/components/WameedPagination.vue';
import WameedNoData from '@/components/WameedNoData.vue';
import PageHeader from '@/components/PageHeader.vue';
import FilterHeader from '@/components/FilterHeader.vue';
// import ordersCard from "@/components/pages/orders/OrdersCard.vue";
import WTables from '@/components/pages/applicants/WTables.vue';
import WarningModal from '@/components/WarningModal.vue';
import { ValidationObserver } from 'vee-validate';
import WameedForm from '@/components/WameedForm.vue';
import WameedBtn from '@/components/WameedBtn.vue';
import TextArea from '@/components/TextArea.vue';

export default {
  components: {
    WameedPagination,
    WameedNoData,
    PageHeader,
    FilterHeader,
    // ordersCard,
    WTables,
    WameedBtn,
    WarningModal,
    ValidationObserver,
    TextArea,
    WameedForm,
  },
  data() {
    return {
      adminId: null,
      filterData: {
        user_id: '',
        page: 1,
        per_page: 10,
        order_by: 'desc',
        search: '',
      },
      administratorsFilterData: {
        status: '',
        page: 1,
        per_page: 'all',
        order_by: 'desc',
        search: '',
      },
      content: [
        {
          title: 'users',
          id: 'id',
          name: 'name',
          key: 'user_id',
          selectedData: [],
          data: [],
        },
      ],
      fields: [
        {
          key: 'name',
          label: this.$i18n.t('users_data.name'),
          sortable: true,
        },
        {
          key: 'operation_description',
          label: this.$i18n.t('common.process_type'),
          sortable: true,
        },
        {
          key: 'created_at',
          label: this.$i18n.t('common.process_time'),
          sortable: true,
        },
      ],
      status: [
        {
          1: 'Current',
          2: 'Professional',
          3: 'Rejected',
          4: 'Resigned',
          5: 'Applied',
        },
        {
          1: 'success',
          2: 'warning',
          3: 'danger',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getAllOperations: 'getAllOperations',
      getTotalOperations: 'getTotalOperations',
      getAllAdministrators: 'getAllAdministrators',
    }),
  },
  watch: {
    getAllAdministrators(data) {
      this.content[0].data = data;
    },
  },
  created() {
    this.loadData();
    this.loadAdministrators(this.administratorsFilterData);
  },
  methods: {
    ...mapActions({
      loadAdministrators: 'loadAdministrators',
      loadOperations: 'loadOperations',
    }),
    changeCurrentPage(item) {
      this.filterData = {
        ...this.filterData,
        per_page: item.id,
      };
      this.loadData();
    },
    changePage(event) {
      this.filterData = {
        ...this.filterData,
        page: event,
      };
      this.loadData();
    },
    loadData() {
      this.loadOperations(this.filterData);
    },
    applyFilter() {
      this.content.forEach((item) => {
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },
    resetFilter() {
      this.content.forEach((item) => {
        item.selectedData = [];
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },
    orderApplicants(item) {
      this.filterData = {
        ...this.filterData,
        ...item,
      };
      this.loadData();
    },
    formatDate(date) {
      if (date) {
        this.moment.locale(this.$i18n.locale === 'ar' ? 'ar_SA' : 'en_US');
        return this.moment(date).format('YYYY/MM/DD -- h:MMA');
      }
      return '------';
    },
  },
};
</script>
